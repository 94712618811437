.logo {
  background-image: url("../images/logo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 131px;
  height: 30px;
  font-size: 0;
  display: block;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &._alt {
    background-image: url("../images/logo-footer.png");
    width: 160px;
    height: 80px;
    background-size: contain;
  }
  &._alt-labi {
    background-image: url("../images/logo-footer-labi.png");
    width: 160px;
    height: 80px;
    background-size: contain;
  }


  &._yellow {
    background-image: url("../images/logo-yellow.png");
  }

  &._print {
    background-image: url("../images/logo-print.png");
    width: 208px;
    height: 36px;
    background-size: contain;
  }
}
