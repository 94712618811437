pre {
    padding: 16px 27px 23px;
    background-color: #f5f5f5;
    border-radius: 16px;
    margin-bottom: 48px !important;
    color: #666;
    font-weight: 100;
    white-space: normal;
    font-family: Open Sans; 
    font-size: 18px;
    line-height: 32px;
    strong {
        color: #572580;
        font-weight: 600;
    }
}

p.comment-form-author {
    float: none;  
}

p.comment-form-email {
    float: none;
}

@media screen {
    @media (min-width: $screen-sm) {

        p.comment-form-author {
            float: left;
            width: 48%;
        }
        
        p.comment-form-email {
            float: right;
            width: 48%;
        }
    }
  }
  