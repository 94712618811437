.list-exams-post {
  padding-left: 100px;
  list-style: none;
  margin: 0;
  li {
    font-size: 18px;
    line-height: 36px;
    font-style: italic;
    color: #666666;
    font-weight: 300;
    &:before {
      content: "\2022";
      color: $color-purple;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}
