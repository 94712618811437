.app-footer-nav {
	border-radius: 20px 20px 0 0;
	background: $color-purple;
	padding-top: 70px;
	text-align: center;

	.logo {
		margin: 0 auto;
	}
	.row > div {
		margin-bottom: 60px;
	}
	@media screen {
		@media (min-width: $screen-sm) {
			margin-top: 35px;
		}

		@media (min-width: $screen-md) {
            border-radius: 70px 70px 0 0;
            text-align: left;
            
			.logo {
				margin: 0;
			}
		}
	}
}