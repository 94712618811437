.owl-carousel {

  .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    button {
      span {
        height: 8px;
        width: 8px;
        background-color: rgba(153, 153, 153, 0.2);
        display: block;
        margin: 0 4px;
        border-radius: 50%;
      }
      &.active{
          span{
            background-color: rgba(153, 153, 153, 0.5);
          }
      }
    }
  }

}
