.section-newsletter {
  > .container {
    position: relative;
  }
  .title-md {
    text-align: center;
  }
  .image-newsletter {
    max-width: 110%;
    margin-left: -40px;
  }
  .form-comments{
      margin-bottom: 40px;
  }
  @media screen {
    @media (min-width: $screen-xs) {
      .image-newsletter {
        max-width: 100%;
        display: table;
        margin: 0 auto;
      }
    }
    @media (min-width: $screen-md) {
      .title-md {
        text-align: left;
      }
      .image-newsletter {
        margin-top: -50px;
      }
      .form-comments{
          margin-bottom: 0;
      }
    }
    @media (min-width: $screen-lg) {
      .image-newsletter {
        position: absolute;
        top: -110px;
        left: -80px;
        margin-top: 0;
      }
    }
  }
}
