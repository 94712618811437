.group-button-icon{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    i{
        color: $color-purple;
        font-size: 28px;
        min-width: 48px;
        display: inline-block;
        min-height: 48px;
        line-height: 48px;
        text-align: center;
        background-color: #f0f0f0;
        border-radius: 50%;
        margin-right: 16px;
    }
}