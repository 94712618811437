.tags-post {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 40px;

	span {
		font-size: 14px;
		line-height: 18px;
		font-family: $font-display;
		color: #666;
		margin-right: 24px;
		margin-bottom: 10px;
	}
	a {
		padding: 6px 18px;
		margin: 10px 7px 10px 0;
	}
	@media screen {
		@media (min-width: $screen-lg) {
			a {
				margin: 0 16px 10px 0;
			}
		}
	}
}
