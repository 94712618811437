.card {
  border-radius: 24px;
  box-sizing: border-box;

  &.card-category {
    transition: 0.5s transform;
    overflow: hidden;
    position: relative;
    margin-bottom: 24px;
    padding: 24px 16px;
    min-height: 205px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    &:hover{
      transform: scale(1.01);
    }
    .button {
      margin-bottom: 10px;
    }
    .content {
      position: relative;
      z-index: 1;
      max-width: 449px;
      .desc {
        color: white;
        font-size: 24px;
        line-height: 32px;
        font-weight: 100;
        margin-bottom: 16px;
      }
      .info-card-blog-dest {
        color: white;
      }
    }

    &._lg {
      min-height: 400px;
    }
    @media screen {
      @media (min-width: $screen-sm) {
        margin-bottom: 10px;
        min-height: 222px;
        &._lg {
            min-height: 464px;
          }
      }
      @media (min-width: $screen-md) {
        padding: 27px 35px;
        &._lg {
          min-height: 456px;
          .content {
            .desc {
              font-size: 32px;
              line-height: 40px;
            }
          }
        }
        .desc {
          font-size: 28px;
        }
      }
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      z-index: 0;
      background-image: linear-gradient(180deg, #3D0060 9%, #904CB7 100%);
    }
  }
}
