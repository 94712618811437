.pagination {
  width: 100%;
  margin-top: 3px;
  .menu-paginate {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    li {
      a {
        height: 40px;
        width: 40px;
        display: table;
        line-height: 40px;
        text-align: center;
        border: 1px solid #999;
        border-radius: 30px;
        margin: 0 6px;
        color: #999;
        font-family: $font-display;
        font-size: 14px;
        text-transform: uppercase;
        transition: all 0.3s;
        &:hover {
          border-color: $color-purple;
          color: $color-purple;
        }
      }
      &.next,
      &.previous {
        a {
          padding: 0 28px;
        }
      }
      &.active{
          a{
            border-color: $color-purple;
            color: $color-purple;
          }
      }
      &.disable{
        a{
          opacity: 0.3;
          pointer-events: none;
        }
    }
    }
  }
}


.wp-pagenavi {
  width: 100%;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  a, span.current{
    height: 40px;
    width: 40px;
    display: table;
    line-height: 40px;
    text-align: center;
    border: 1px solid #999;
    border-radius: 30px;
    margin: 0 6px;
    color: #999;
    font-family: "Saira",sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    transition: all 0.3s;
    &:hover {
      border-color: $color-purple;
      color: $color-purple;
    }
  }

  span.current{
  border-color: #572580;
    color: #572580;
  }
  .previouspostslink,.nextpostslink{
    padding: 0 28px;
  }

}