section{
    padding:0 0 64px;
    &.section-gray-light {
        background-color: #f9f9f9;
        padding-bottom: 64px;
    }
    &.section-gray {
        background-color: #f0f0f0;
    }
    &.section-more-read{
        padding: 64px 0 64px;
    }
    &.section-newsletter{
        padding: 64px 0 142px;
    }
    &.section-separate-bigger{
        padding-bottom: 96px;
    }
    &.section-separate-small{
        padding-bottom: 38px;
    }
}
.section-bottom{
    margin-bottom: -65px;
    padding-bottom: 129px;
}