.button {
	color: white;
	background: $color-purple;
	border-radius: 25px;
	padding: 6px 40px;
	display: inline-block;
	text-transform: uppercase;
	text-decoration: none;
	font-family: $font-display;
	border: 2px solid $color-purple;
	line-height: 22px;
	box-sizing: border-box;
	font-size: 14px;
	text-align: center;
	transition: all .33s ease-in-out;
	cursor: pointer;

	&:hover {
		background: white;
		color: $color-purple;
	}


	&._yellow {
		background: $color-yellow;
		color: $color-purple;
		border-color: $color-yellow;

		&:hover {
			background: $color-purple;
			color: $color-yellow;
		}
	}

	&._alt {
		background: transparent;
		border: 2px solid $color-purple;
		color: $color-purple;

		&:hover {
			background: $color-purple;
			color: white;
		}

		&._yellow {
			background: transparent;
			border: 2px solid $color-yellow;
			color: $color-yellow;

			&:hover {
				background: $color-yellow;
				color: $color-purple;
			}
		}
	}
	&._alt-white {
		background: transparent;
		border: 2px solid #E0E0E0;
		color: white;

		&:hover {
			background: white;
			border-color: white;
			color:  $color-purple;
		}
	}

	&._alt_md {
		padding: 11px 38px;
		line-height: 22px;
	}

	&._lg {
		padding: 10px 40px;
	}

	&._xlg {
		padding: 13px 50px;
		text-align: center;
	}

	&._horizontal-xlg {
		padding: 12px 34px;
		text-align: center;
	}

	&._white {
		background-color: white;
		color: $color-purple;

		&:hover {
			background-color: $color-purple;
			color: white;
		}
	}

	&._grey {
		border: 1px solid $color-purple;
		background-color: $color-ltgray;
		color: $color-purple;

		&:hover {
			color: $color-ltgray;
			background-color: $color-purple;
		}
	}

	&._sm {
		padding: 3px 30px;
		font-size: $font-size-sm-md;
	}

	&._xsm {
		padding: 4px 40px;
		font-size: $font-size-xs;
		line-height: 16px;
		font-size: 12px;
	}

	&._xs {
		padding: 6px 20px 8px;
		font-size: 13px;
		line-height: 18px;
	}

	&._xsc {
		padding: 7px 27px 5px;
		font-size: 12px;
		line-height: 18px;
	}

	&._menu {
		padding: 4px 23px 6px;
		line-height: 22px;
		font-size: 14px;
		text-transform: none;
	}

	&._button-font-text {
		font-family: $font-text;
	}

	&._light-yellow {
		background-color: #FAF38D;
		border-color: #FAF38D;

		&:hover {
			background-color: #EAE48A;
			border-color: #EAE48A;
		}
	}

	&._disabled,
	&[disabled=""] {
		background-color: $color-ltgray;
		border: transparent;
		color: $color-gray;
	}

	&._disabled-purple,
	&[disabled=""] {
		color: $color-purple;

		i
		{
			color: $color-purple;
		}
	}

	&._icon-left {
		i {
			float: left;
			font-size: 18px;
			margin-right: 12px;
			position: relative;
			top: 2px;
		}
	}

	&._pagination {
		padding: 7px;
		width: 40px;
		text-align: center;
		line-height: 22px;
		background-color: white;
		border-color: $color-lgray-text;
		color: $color-lgray-text;
		font-size: 14px;

		&:hover {
			border-color: $color-purple;
			color: $color-purple;
		}
	}

	&._label {
		position: relative;

		span {
			display: inline-block;
			border-radius: 30px;
			font-size: 12px;
			line-height: 18px;
			padding: 3px 10px;
			position: absolute;
			right: 0;
			top: -20px;
			transition: all .3s ease-in-out;
			border: 1px solid transparent;

			&._white {
				background-color: white;
				color: $color-purple;
				border: 1px solid $color-purple;
			}

			&._yellow {
				background-color: $color-yellow;
				color: $color-purple;
				border: 1px solid $color-purple;
			}
		}

		&:hover {
			span {
				&._white {
					background-color: $color-purple;
					color: white;
				}

				&._yellow {
					background-color: $color-purple;
					color: $color-yellow;
				}
			}
		}
	}

	&._bg-purple {
		&:hover {
			background-color: $color-purple;
		}
	}

	&._bg-yellow {
		background-color: $color-yellow;
		color: $color-purple;
	}

	&._border-white {
		border-color: white;
		color: white;

		&:hover {
			color: $color-purple;
		}
	}
}