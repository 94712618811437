.field-holder,.comment-form-comment {
    margin-bottom: 24px;


    .error-message {
        font-size: 10px;
        line-height: 14px;
        text-align: right;
        color: $color-danger;
    }

    // Modifiers
    &.field-holder-invalid {
        .error-message {
            position: absolute;
            top: 4px;
            right: 8px;
        }

        .input, textarea, input {
            border-color: $color-danger;
            background-color: rgba(224, 159, 157, 0.25);
        }
    }
}