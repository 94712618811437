.copyright-footer-menu {
  .copyright-and-legal-menu {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    li {
      a {
        color: transparentize(white, 0.6);
        text-decoration: none;
        font-size: 12px;
        line-height: 30px;
      }
    }
    @media screen {
      @media (min-width: $screen-sm) {
        li {
          display: inline-block;
          margin: 0 20px;
        }
      }
    }
  }
  @media screen {
    @media (min-width: $screen-md) {
      position: absolute;
      bottom: 0;
    }
  }
}
