.body-card-blog-dest {
  padding: 0;
  margin-bottom: 64px;
  .desc {
    margin-top: 0;
    font-family: $font-display;
    color: $color-purple;
    margin: 12px 0 18px;
    font-size: 24px;
    line-height: 32px;
  }
  a.button._alt._white._xsc {
    margin-bottom: 10px;
}
  
  @media screen {
    @media (min-width: $screen-md) {
      padding: 0;
      .desc {
        margin-top: 0;
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
}
