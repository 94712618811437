.card-news{
    min-height: 400px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 0 0 1px rgba(130,136,148,0.16), 0 8px 10px -4px rgba(130,136,148,0.28);
    &:before{
        transition: 0.5s opacity;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 21px;
        top: 0;
        left: 0;
        background-color: rgba(87, 37, 128, 0.75);
        z-index: 0;
        opacity: 0;
    }
    &:hover{
        &:before{
            opacity: 1;
        }
        span{
            color: white;
            
        }
    }

    span{
        text-align: center;
        color: $color-purple;
        font-size: 20px;
        line-height: 24px;
        font-family: $font-display;
        z-index: 1;
        transition: 0.5s color;
        position: relative;
    }
    .icon-content{
        position: relative;
        z-index: 1;
        height: 96px;
        width: 96px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        i{
        padding: 22px;
        font-size: 52px;
        color: $color-purple;
    }
    }
    @media screen {
        @media(min-width: $screen-sm){
            min-height: 480px;
            span{
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
    
}
