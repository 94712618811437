label {
    font-size: $font-size-body-md;
    font-weight: 600;
    display: block;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0 8px 8px;

    &._purple {
        color: $color-purple;
    }
}