body {
	font-family: $font-text;
	font-size: $font-size-body;
	color: $font-color-body;
	line-height: 1.4;
}

p {
	margin-bottom: 30px;
	margin-top: 0;
	color: #666;
	margin-bottom: 15px;
	font-size: 18px;
	line-height: 32px;
	font-weight: 300;

	&.text-page-description {
		font-size: $font-size-sm-md;
		color: #999;
	}  
}
.text-post{
	font-size: 18px;
	line-height: 32px;
	font-weight: 300;
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
	margin-top: 0;
	font-family: $font-display;
	font-weight: $font-weight-heading;

	&.title-md{
		font-size: 28px;
		line-height: 36px;
	}

	&.title-content {
		line-height: 24px;
        font-size: $font-size-h4;
        color: $color-purple;
        margin-bottom: 24px;
        text-align: center;
        font-weight: 700;
	}

	&.title-blog{
		font-size: 20px;
		line-height: 36px;
		color: #666;
		font-weight: 300;
		font-family: $font-display;
		margin-bottom: 48px;
		text-transform: uppercase;
	}

	&.title-section {
		margin-left: auto;
		margin-right: auto;
		font-size: $font-size-h2-md;
    }
    
    @media screen {
        @media (max-width: $screen-sm - 1) {
            &.title-section {
                font-size: $font-size-h3;
                line-height: $line-height-h5;
            }
        }
    }
}

h1, .h1 {
	font-size: $font-size-h1;
	line-height: $line-height-h1;
}
h2, .h2 {
	font-size: 26px;
	line-height: 38px;
	color: $color-purple;
}
h3, .h3 {
	font-size: 22px;
	line-height: $line-height-h4;
}
h4, .h4 {
	font-size: 22px;
	line-height: $line-height-h4;
}
h5, .h5 {
	font-size: $font-size-h5;
	line-height: $line-height-h5;
}

// Sizes
.lead {
	font-size: $font-size-lead;
}

small, .small {
    font-size: $font-size-sm-md;
    line-height: 22px;
}
.xsmall {
    font-size: 10px !important;
    line-height: 18px;
}

.uppercase {
	text-transform: uppercase !important;
}
.capitalize {
	text-transform: capitalize !important;
}
.transform-none {
	text-transform: none !important;
}

.text-underline {
    text-decoration: underline;
}

// Alignment
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}

.text-centered {
	margin: 0 auto;
}

// Weight
.text-semibold {
	font-weight: 600 !important;
}
.text-bold {
	font-weight: 700 !important;
}
.text-medium {
	font-weight: 400 !important;
}

// Color
.text-lgray {
	color: $color-lgray-text !important;
}
.text-hgray {
	color: $color-hgray-text !important;
}
.text-gray {
	color: $color-gray-text !important;
}
.text-white {
	color: white !important;
}
.text-purple {
	color: $color-purple !important;
}
.text-yellow {
	color: $color-yellow !important;
}
.text-danger {
	color: $color-danger !important;
}
.text-success {
	color: $color-success !important;
}
.text-pink {
    color: #815CA0 !important;
}

// Separators
.title-separate-content {
	margin-bottom: $separate-title-from-content;
}

.text-separate-content {
	margin-bottom: $separate-text-from-content;
}

.text-separate-text {
	margin-bottom: $separate-base;
}

.text-no-margin {
	margin-bottom: 0 !important;
}

// Opacity
.text-opacity-75 {
	opacity: 0.75;
}
.text-opacity-50 {
	opacity: 0.50;
}
.text-opacity-25 {
	opacity: 0.25;
}

// Diagram
.text-block {
	display: block;
}

.text-display {
	font-family: $font-display;
}

.font-text {
	font-family: $font-text;
}

input, button{
	cursor: pointer;
	&:focus{
		outline:none;
	}
}
a{
	text-decoration: none;
	color: $color-purple;
	&:hover{
		color: #000;
	}
}

@media screen {
	@media (min-width: $screen-sm) {
		.sm-text-inline-block {
			display: inline-block;
		}
	}
}