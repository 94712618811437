.input {
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16), 0 1px 2px -1px rgba(130, 136, 148, 0.48);
    border-radius: 8px;
    background-color: #FFF;
    border: none;
    display: block;
    padding: 13px 16px;
    font-size: $font-size-body-md;
    line-height: 22px;
    color: $color-gray-text;
    text-align: left;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #FFF;

    &::placeholder {
        color: rgba(153, 153, 153, 0.5);
    }

    &.textarea {
        height: 128px;
        resize: none;
    }

    &._lgray {
        color: $color-lgray-text;

        &::placeholder {
            color: $color-lgray-text;
        }
    }

    &._circle {
        border-radius: 30px;
    }

    &._transparent {
        background-color: transparent;
    }

    &._border-purple {
        border: 1px solid $color-purple;
    }

    &._invalid {
        border-color: #C23F3B;
    }

    &._sm {
        padding: 5px 145px 7px 20px;
        line-height: 18px;
        font-size: 13px;
    }
}