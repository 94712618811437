.app-hero {
  border-radius: 0 0 48px 48px;
  padding: 122px 0 110px;
  text-align: center;
  position: relative;
  z-index: 2;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  &.with-content{
    padding: 120px 0 82px;
  }
  > div{
    position: relative;
    z-index: 2;
  }
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(48, 10, 78, 0.81);
    border-radius: 0 0 48px 48px;
  }

  h1 {
    color: white;
    font-size: 32px;
    line-height: 44px;
    font-weight: 300;
    margin-bottom: 0;

    span {
      display: block;
    }
  }
  @media screen {
    @media (min-width: $screen-sm) {
    border-radius: 0 0 64px 64px;
    &:before{
      border-radius: 0 0 64px 64px;

    }
		h1{
			font-size: 36px;
		}
    }
  }
}
