.img-responsive {
	width: 100%;
}
.content-img {
	border-radius: 24px;
}

.content-img {
	.img-responsive-card {
		@media screen and (min-width: $screen-sm) {
			height: 230px;
		}
	}
}

.section-more-read {
	.img-responsive-card {
		@media screen and (min-width: $screen-sm) {
			height: 230px;
		}
	}
}

.img-responsive-card {
	width: 100%;
	// height: 300px !important;
	object-fit: cover;
	object-position: center;
	border-radius: 24px;
	margin-bottom: 40px !important;
	transition: 0.5s transform;

	&:hover {
		transform: scale(1.02);
	}

	@media screen and (min-width: $screen-sm) {
		margin-bottom: 40px;
	}

	&._lg {
		height: 300px;
		margin-bottom: 40px;

		@media screen and (min-width: $screen-sm) {
			height: 320px;
			margin-bottom: 0;
		}
	}
}
