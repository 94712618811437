.content-featured{
    padding: 16px 27px 23px;
    background-color: #f5f5f5;
    border-radius: 16px;
    p{
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
    }
    b{
        color: $color-purple;
    }
}