.app-header {
    background: $color-purple;
    padding: 25px 0;
    z-index: 100;
    transition: top 2s ease-in-out;

    .logo {
        float: left;
    }

    .menu-toggle {
        cursor: pointer;
        transition: top 0.3s ease-in-out;
    }

    .app-header-mobile-actions {
        float: right;
        display: flex;
        align-items: center;
        height: 36px;
    }

    &.app-header-logo-only {
        background: $color-purple;
        border-radius: 0 0 48px 48px;
        padding: 47px 0 53px;

        .logo {
            width: 160px;
            height: 28px;
        }
    }

    &.app-header-logo-center {
        .logo {
            float: none;
            margin: 0 auto;
        }
    }

    &.app-header-auth {
        margin-bottom: 24px;
    }

    &.app-header-checkout {
        margin-bottom: 28px;
        padding: 27px 0 33px;
    }

    &.app-header-main {
        box-sizing: border-box;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        transition: all 0.3s ease-in-out;

        &:before {
            content: "";
            position: absolute;
            width: 100vw;
            height: 100vh;
            z-index: -1;
            background-color: rgba(87, 37, 128, 0.55);
            transition: opacity 0.4s ease-in-out;
            opacity: 0;
            pointer-events: none !important;
        }

        &+.app-content {
            padding-top: 80px;
            transition: padding 0.3s ease-in-out;
        }
    }

    &.app-fixed-top {
        padding: 13px 0;

        &+.app-content {
            padding-top: 66px;
        }
        .quick-menu {
            border-right-color: #471571;
        }
    }

    &.active {
        background: $color-purple !important;
        &:before {
            opacity: 1;
        }
    }

    @media screen {
        @media (min-width: $screen-sm) {
            &.app-header-main {
                &+.app-content {
                    padding-top: 86px;
                }
            }

            &.app-fixed-top {
                &+.app-content {
                    padding-top: 62px;
                }
            }
        }

        @media (min-width: $screen-md) {
            &.app-header-main {
                &+.app-content {
                    padding-top: 87px;
                }
            }

            &.app-fixed-top {
                &+.app-content {
                    padding-top: 64px;
                }
            }

            &.app-header-checkout {
                position: sticky;
                top: 0;
            }
        }

        @media (max-width: ($screen-md - 1)) {
            &.active {
                z-index: 120;
            }
        }

        @media (max-width: ($screen-sm - 1)) {
            &.app-header-main {
                height: 80px;
            }

            &.app-fixed-top {
                height: 57px;
            }

            &.active {
                height: 100vh;
                overflow: auto;

                &:before {
                    background-color: rgba(87, 37, 128, 1);
                    transition: opacity 1s ease-in-out;
                }
            }
        }
    }
}