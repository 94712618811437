.app-footer-widget {
  h5 {
    color: $color-yellow;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: lighter;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 2;

    &.social-profiles {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 -8px 15px;

      li {
        padding: 0 8px;

        a {
          font-size: 32px;
        }
      }
    }

    a {
      text-decoration: none;
      color: white;
      font-size: 18px;
    }
  }
  @media screen {
    @media (min-width: $screen-sm) {
      &.app-footer-widget-social {
        text-align: right;
      }

      ul {
        &.social-profiles {
          justify-content: flex-end;
        }
      }
    }
    @media (min-width: $screen-md) {
      &.app-footer-widget-social {
        text-align: right;
      }
    }
  }
}
