.info-post {
  color: white;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  .item {
    font-family: $font-display;
    display: flex;
    align-items: center;
    margin-right: 24px;
    &:last-child{
        margin-right: 0;
    }
    img {
        margin-right: 12px;
    }
  }
}
