.send-comment {
  .comment-reply-title {
    color: $color-purple;
  }
  .comment-notes {
    display: none;
  }
  .submit{
    color: white;
    background: #572580;
    border-radius: 25px;
    padding: 10px 40px;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    font-family: "Saira",sans-serif;
    border: 2px solid #572580;
    line-height: 22px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    transition: all .33s ease-in-out;
    cursor: pointer;
    margin-bottom: 50px;
    &:hover {
        background: white;
        color: #572580;
    }
  }
}
.comments-list{
    ul{
        list-style-type: none;
        padding: 0;
        li{
            margin-bottom: 50px;
            &:last-child{
              margin-bottom: 0;
            }
        }
    }
}
