.content-description{
    padding: 48px 0;
    border-bottom: 1px solid #d8d8d8;
    font-style: italic;
    border-top: 1px solid #d8d8d8;
    color: $color-purple;
    display: flex;
    margin-bottom: 48px;
    span{
        font-size: 40px;
        margin-right: 25px;
        font-weight: 300;
    }
    .description{
        margin-bottom: 0;
        font-size: 24px;
        line-height: 36px;
        font-family: $font-text;
        font-weight: 300;
    }
}