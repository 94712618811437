hr {
	border: 0;
	height: 1px;
	background: #d8d8d8;
}
.blog {
	img {
		border-radius: 25px;
	}

	h1,
	h2,
	h3,
	h4,
	p {
		margin-bottom: 40px;
	}

	.wp-block-cover.has-background-dim {
		background-color: #572580;
		color: white;
		padding: 102px 0;
		border-radius: 24px;
		margin-bottom: 64px;
		background-size: cover;
		background-position: center;

		.wp-block-cover__inner-container {
			text-align: center;
			color: white;
		}
		h1,
		h2,
		h3,
		h4,
		p {
			margin-bottom: 0px;
		}
	}
	.wp-block-group__inner-container {
		h4,
		h3 {
			margin-bottom: 10px;
			color: #572580;
		}
	}
	figure {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 0 40px;
		width: 100%;

		figcaption {
			color: #666;
			font-family: $font-text;
			font-size: 14px;
			font-weight: 300;
			line-height: 32px;
			width: 100%;
		}
	}
	blockquote {
		padding: 48px 0;
		border-bottom: 1px solid #d8d8d8;
		font-style: italic;
		border-top: 1px solid #d8d8d8;
		display: flex;
		margin-bottom: 48px;
		margin: 0 0 48px;

		p {
			margin-bottom: 0px !important;
			font-size: 24px;
			line-height: 36px;
			font-family: "Open Sans", sans-serif;
			font-weight: 300;
			color: #572580;
		}
		span {
			font-size: 40px;
			margin-right: 25px;
			font-weight: 300;
			margin-top: -14px;
		}
	}
	ul {
		padding-left: 40px;
		margin: 0 0 40px;
		list-style: none;

		@media screen and (min-width: $screen-sm) {
			padding-left: 100px;
		}

		li {
			font-size: 18px;
			line-height: 36px;
			color: #666666;
			font-weight: 300;
		}
		li:before {
			content: "\2022";
			color: #572580;
			font-weight: bold;
			display: inline-block;
			font-style: normal;
			width: 1em;
			margin-left: -1em;
		}
	}
	.lembrete {
		padding: 16px 27px 23px;
		background-color: #f5f5f5;
		border-radius: 16px;
		margin-bottom: 48px !important;
		color: #666;
		font-weight: 100;
		white-space: normal;
		font-family: "Open Sans", sans-serif;
		strong {
			color: #572580;
			font-weight: 600;
		}

		p {
			margin-bottom: 0px !important;
		}
	}

	p {
		margin-bottom: 40px !important;
	}

	.wp-block-group {
		p {
			margin-bottom: 32px !important;
		}
	}
}
.comment-body {
	a {
		color: #666;
	}
}
@media screen {
	@media (max-width: $screen-sm) {
		figure {
			img {
				max-width: 100%;
				object-fit: cover;
			}
		}
	}
}

.comment-metadata a {
	color: #666666;
}

ol {
	padding-left: 82px;
	margin: 0 0 48px;
	list-style: none;
	counter-reset: my-awesome-counter;
	li {
		font-size: 18px;
		line-height: 36px;
		color: #666666;
		font-weight: 300;
		counter-increment: my-awesome-counter;
	}
	li:before {
		content: counter(my-awesome-counter) ". ";
		font-weight: bold;
		color: #572580;
		font-weight: bold;
		font-style: normal;
	}
}

input::placeholder {
	opacity: 1;
}

input:focus::placeholder {
	opacity: 0;
}

label:focus + input::placeholder {
	opacity: 0;
}

figure.wp-block-image img {
	border-radius: 27px;
	max-width: 100%;
}
