.app-footer {
    position: relative;
    margin-top: -423px;
    padding-top: 335px;
    background: $color-yellow;
    z-index: 1;

    &.app-footer-entry {
        margin-top: 0;
        padding-top: 0;
        background-color: transparent;

        // Modifiers
        .app-footer-nav {
            margin-top: 0;
        }
    }

    @media screen {
        @media (min-width: $screen-sm) {
            margin-top: -307px;
            padding-top: 307px;

        }
    }

    @media screen {
        @media (min-width: $screen-md) {
            margin-top: -353px;
            padding-top: 280px;

        }
    }

    @media screen {
        @media (min-width: $screen-lg) {
            margin-top: -308px;
            padding-top: 245px;

        }
    }
}