.main-nav {
    max-height: 0;
    overflow: hidden;
    margin: 0;
    transition: max-height 0.3s ease-in-out;

    .quick-menu {
        display: inline-block;
        padding: 0;
        border: none;
        margin:  30px 0 0;
    }

    >.main-menu {
        padding: 0;
        margin:  30px 0;

        >li {
            list-style: none;
            position: relative;

            a,
            button {
                color: white;
                text-decoration: none;
                font-family: $font-display;
                font-size: 20px;
                line-height: 48px;
                display: block;
                width: 100%;
                border: none;
                outline: none;
                background-color: transparent;
                padding: 0;
                text-align: left;
                font-weight: 300;
                cursor: pointer;

                &:hover {
                    color: $color-yellow;
                }

                &.selected {
                    color: $color-yellow;
                }
            }

            &.item-menu-horizontal {
                color: #FFF;

                a,
                button {
                    width: auto;
                    font-weight: 600;
                    display: inline-block;
                }
            }

            >.sub-menu {
                margin-left: 15px;
                padding-left: 0;

                li {
                    list-style: none;

                    a:before {
                        content: "•";
                        margin-right: 5px;
                    }
                }
            }

            &.menu-item-submenu {
                >a {
                    &:after {
                        content: ">";
                        transform: scaleY(0.8) scaleX(1.7) rotate(90deg);
                        transition: transform 0.3s ease-in-out;
                        display: inline-block;
                        font-size: 10px;
                        color: white;
                        margin-left: 13px;
                    }
                }

                &:hover {
                    >a {
                        &:after {
                            transform: scaleY(0.8) scaleX(1.7) rotate(-90deg);
                        }
                    }
                }
            }
        }
    }

    &.opened {
        max-height: 1000px;
        
    }

    @media screen {
        @media (min-width: $screen-sm) {
            .quick-menu {
                display: none;
            }
        }
    }
}