.quick-menu {
	display: none;
	justify-content: flex-end;
	padding: 0;
	margin: 0;
	list-style: none;
	padding-right: 25px;
	margin-right: 25px;
	border-right: 2px solid #572580;

	li {
		margin-right: 30px;

		a {
			color: white;
			text-decoration: none;
			font-family: $font-display;
			font-size: 14px;
			line-height: 45px;

			i {
				vertical-align: middle;
				width: 40px;
				height: 30px;
				display: inline-block;
				font-size: 30px;
				text-align: center;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}
	@media screen {
		@media (min-width: $screen-sm) {
			display: flex;
			float: right;
			width: 267px;
		}



		@media (min-width: $screen-md) {

			li {
				line-height: 37px;

				a {
					line-height: 41px;
					display: inline-block;
				}
			}
		}
	}
}

@media (max-width: $screen-sm) {
	ul.quick-menu {
		width: 100%;
	}
	input#catalogSearch{
		max-width: 100%;
		width: 100%;
	}
}