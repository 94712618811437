.social-share {
	display: flex;
	align-items: center;
	margin-bottom: 40px;

	span {
		font-size: 14px;
		line-height: 18px;
		font-family: $font-display;
		color: #666;
		margin-right: 20px;
	}
	a {
		margin-right: 14px;
		line-height: 0;
		font-size: 32px;
		color: #572580;
	}
}
