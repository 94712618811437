.gps-content{
    cursor: pointer;
    background-image: url(../images/banner-gps-mobile.jpg);
    height: 490px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;


    @media screen {
        @media (min-width: $screen-sm){
            background-image: url(../images/banner-gps-desktop.jpg);
            height: 320px;
        }
    }
}