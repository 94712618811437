@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tv4au8');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tv4au8#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?tv4au8') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?tv4au8') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?tv4au8##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twiter {
  &:before {
    content: $icon-twiter; 
  }
}
.icon-chat-fantasma {
  &:before {
    content: $icon-chat-fantasma; 
  }
}
.icon-alvo {
  &:before {
    content: $icon-alvo; 
  }
}
.icon-linkedin-fulll {
  &:before {
    content: $icon-linkedin-fulll; 
  }
}
.icon-loupe {
  &:before {
    content: $icon-loupe; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-microscope {
  &:before {
    content: $icon-microscope; 
  }
}
.icon-account {
  &:before {
    content: $icon-account; 
  }
}
.icon-tube {
  &:before {
    content: $icon-tube; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-facebook-fill {
  &:before {
    content: $icon-facebook-fill; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-heart {
  &:before {
    content: $icon-heart; 
  }
}
.icon-tests {
  &:before {
    content: $icon-tests; 
  }
}

