* {
	// -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
	// -moz-font-feature-settings: "liga","kern";
}

body {
	margin: 0;
	padding: 0;

    &.mapa-do-site {
        .app-hero {
            .h1.text-left.small-separate {
                text-align: center;
            }
            
            .info-post {
                display: none;
            }
        }

        .social-share + img {
            display: none;
        }

        .section-separate-bigger {
            .social-share {
                display: none;
            }

            .wsp-container {
                ul {
                    padding: 0;
                    margin: 0;

                    li {
                        &::before, strong {
                            display: none;
                        }
                    }
                }

                .wsp-post_anuncios-list {
                    display: none;
                }
            }
        }
    }
}

.teste-layout{
	position: absolute;
    top: 0;
    left: 0;
    opacity: 0.1;
    z-index: 10000000000000000;
}