// Grid settings
$grid-gutter-width: 16px;
$grid-columns: 12;

$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1280px;

$grid-container-max-size: 1280px;

// Colors settings
$color-yellow: #f6e71c;
$color-purple: #572580;
$color-ltgray: #f5f5f5;
$color-gray: #b1b1b1; // Extracted from layout
$color-gray-line: #cccccc;
$color-black: #333333;
$color-success: #3ab572;
$color-organe: #f0ac4a;
$color-danger: #c23f3b;
$color-pink: #815ca0;

$color-lgray-text: #999;
$color-gray-text: #666;
$color-hgray-text: #333;

// Typography settings
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i|Saira:300,400,700');

$font-display: "Saira", sans-serif;
$font-text: "Open Sans", sans-serif;
$font-weight-heading: 400;

$font-color-body: $color-black;

$font-size-h1: 36px;
$font-size-h2: 32px;
$font-size-h3: 28px;
$font-size-h4: 24px;
$font-size-h5: 20px;
$font-size-lead: 18px;
$font-size-body: 16px;
$font-size-sm: 15px;
$font-size-xs: 12px;

$font-size-h1-md: 48px;
$font-size-h2-md: 36px;
$font-size-h3-md: 32px;
$font-size-h4-md: 28px;
$font-size-h5-md: 24px;
$font-size-lead-md: 20px;
$font-size-body-md: 14px;
$font-size-sm-md: 13px;
$font-size-xs-md: 10px;

$line-height-h1: 56px;
$line-height-h2: 44px;
$line-height-h3: 40px;
$line-height-h4: 36px;
$line-height-h5: 32px;

// Separators
$separate-base: 40px;
$separate-title-from-content: $separate-base * 2;
$separate-text-from-content: $separate-base + 16px;

// Icons
$icomoon-font-family: "labiicons" !default;
$icomoon-font-path: "fonts" !default;

$icon-twiter: "\e904";
$icon-chat-fantasma: "\e900";
$icon-alvo: "\e901";
$icon-linkedin-fulll: "\e902";
$icon-loupe: "\e903";
$icon-instagram: "\e906";
$icon-facebook: "\e907";
$icon-linkedin: "\e908";
$icon-microscope: "\e909";
$icon-account: "\e90b";
$icon-tube: "\e90f";
$icon-whatsapp: "\e910";
$icon-facebook-fill: "\e911";
$icon-close: "\e915";
$icon-heart: "\e916";
$icon-tests: "\e917";

// Z-index
$z-index-base: 10;
$header-checkout-zindex: $z-index-base + 1;
