.search-input-holder{
    border-radius: 30px;
    font-size: 14px;
    line-height: 22px;
    padding: 4px 30px 4px 40px;
    position: relative;
    &._bwhite{
        border: 1px solid #f0f0f0;
    }
    .search-input{
        background-color: transparent;
        border: none;
        font-size: 14px;
        line-height: 22px;
        font-weight: 100;
        color: #fff;
        padding: 0;
        max-width: 140px;
        text-align: center;
        font-family: $font-display;
        appearance: none;
        -webkit-appearance: none;
        width: 100%;
        &::placeholder {
            color: white;
            text-align: center;
            display: table;
            margin: 0 auto;
          }
    }
    .search-button{
        background-color: transparent;
        border: none;
        font-size: 17px;
        line-height: 22px;
        padding: 0;
        color: #fff;
        position: absolute;
        right: 13px;
    }
}